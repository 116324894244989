import React from 'react';
import Head from 'next/head';

import {useBreakpoints} from '@/utils/hooks';
import {useScrollWithDuration} from '@/utils/hooks';
import FooterV2 from '@/components/redesign/FooterV2';
import NavBarV2 from '@/components/redesign/NavBarV2';
import FadeInV2 from '@/components/redesign/FadeInV2';
import Tabs, {Tab} from '@/components/redesign/Tabs';
import FeaturedShows from '@/components/redesign/FeaturedShows';
import ButtonV2 from '@/components/redesign/ButtonV2';
import {CTAButton} from '@/components/CTAButton';
import {DynamicImage} from '@/components/DynamicImage';

const SHOW_CTA = false;

const COMPANY_INFO_TABS: Tab[] = [
  {
    key: 'community',
    tabName: 'Community members',
    heading:
      'Shibuya provides fans with access to the highest quality undiscovered films and shorts',
    description: (
      <>
        <p>
          To take it a step further, we allow community members to help
          kickstart their favorite IPs and own a share in their upside via our
          crowdfunding portal.
        </p>
        <p>
          We also provide access to IP specific communities where members have
          direct access to their favorite creators, exclusive events, limited
          edition merchandise, and the opportunity to participate in the
          creative process for the films they love most.
        </p>
      </>
    ),
    videos: [
      {
        url: 'https://storage.googleapis.com/shibuya-white-rabbit/shibuya-artist-bg.webm',
        format: 'video/webm',
      },
      {
        url: 'https://storage.googleapis.com/shibuya-white-rabbit/shibuya-artist-bg.mp4',
        format: 'video/mp4',
      },
    ],
    imageUrl:
      'https://storage.googleapis.com/shibuya-white-rabbit/shibuya-artist-bg.jpg',
  },
  {
    key: 'studios',
    tabName: 'Studios & Distributors',
    heading: 'Shibuya is the go-to platform for undiscovered IP.',
    description: (
      <>
        <p>
          If you have distribution and are interested in exploring how we can
          partner, please reach out to{' '}
          <a className="hover:underline" href="mailto:mark@shibuya.film">
            mark@shibuya.film
          </a>
          .
        </p>
        <p>
          Our creators often rely on Shibuya to connect them with studios, show
          writers, directors, and show runners. If you&apos;re interested in
          collaborating, please reach out to{' '}
          <a className="hover:underline" href="mailto:mark@shibuya.film">
            mark@shibuya.film
          </a>
          .
        </p>
      </>
    ),
    videos: [
      {
        url: 'https://storage.googleapis.com/shibuya-white-rabbit/control-panel.webm',
        format: 'video/webm',
      },
      {
        url: 'https://storage.googleapis.com/shibuya-white-rabbit/control-panel.mp4',
        format: 'video/mp4',
      },
    ],
    imageUrl:
      'https://storage.googleapis.com/shibuya-white-rabbit/control-panel.jpg',
  },
  {
    key: 'creators',
    tabName: 'Creators',
    heading: 'Shibuya campaigns turn stories into shorts.',
    description: (
      <>
        <p>
          Our platform is where creators share ideas for films with the
          communities that will come together to fund them. Our platform
          provides creators with a full-stack solution to crowdfund, build
          community, host events, and grow an engaged fanbase.
        </p>
        <p>
          Take it a step further and convert passive fans into active
          participants in the creative process with Shibuya&apos;s interactive
          storytelling toolbox. Build a bespoke page and offering to fit your
          film&apos;s needs and control how the work comes together.
        </p>
      </>
    ),
    videos: [
      {
        url: 'https://storage.googleapis.com/shibuya-white-rabbit/artists.webm',
        format: 'video/webm',
      },
      {
        url: 'https://storage.googleapis.com/shibuya-white-rabbit/artists.mp4',
        format: 'video/mp4',
      },
    ],
    imageUrl: 'https://storage.googleapis.com/shibuya-white-rabbit/artists.jpg',
  },
];

const LandingPage = () => {
  const {ref, scrollTo} = useScrollWithDuration();
  const {isMobile} = useBreakpoints();

  return (
    <div className="flex flex-auto flex-col overflow-hidden font-rules text-white">
      <Head>
        <title> Shibuya</title>
      </Head>
      <main className="bg-dark bg-cover bg-repeat">
        {SHOW_CTA ? (
          <div className="fixed bottom-16 right-16 z-50">
            <FadeInV2 direction="left" delay={1000}>
              <>
                <div className="flex justify-center">
                  <DynamicImage
                    src="https://storage.googleapis.com/shibuya-white-rabbit/bl_kick.gif"
                    alt="bruce"
                    layout="fixed"
                    width={118}
                    height={118}
                  />
                </div>
                <CTAButton
                  title="Bruce Lee x Shibuya"
                  description="Enter the House of Lee"
                  url="/brucelee"
                />
              </>
            </FadeInV2>
          </div>
        ) : null}

        {isMobile ? (
          <div className="absolute h-screen min-h-[800px] w-full">
            <DynamicImage
              layout="fill"
              className="w-full h-full opacity-60"
              src="https://storage.googleapis.com/shibuya-white-rabbit/reel-v2.jpg"
              alt="Landing Page Hero"
              height={800}
              width={640}
            />
          </div>
        ) : (
          <video
            autoPlay
            playsInline
            muted
            loop
            preload="auto"
            className="absolute h-screen min-h-[800px] w-full object-cover"
          >
            <source
              src="https://storage.googleapis.com/shibuya-white-rabbit/landing-page-reel-v2.webm"
              type="video/webm"
            />
            <source
              src="https://storage.googleapis.com/shibuya-white-rabbit/landing-page-reel-v2.mp4"
              type="video/mp4"
            />
          </video>
        )}

        <NavBarV2 className="relative z-10 bg-transparent" />
        <div className="flex h-[calc(100vh-132px)] min-h-[calc(800px-132px)] flex-col justify-center p-16 px-9 pb-[132px] text-grayscale-300 sm:items-start md:px-16">
          <FadeInV2 className="-mt-[132px] ease-in" direction="down" delay={0}>
            <h1 className="shib-title mt-24 mb-2 w-full font-medium drop-shadow-[0_4px_10px_rgba(29,29,31,0.25)] sm:mb-4 lg:mt-0">
              Welcome to Shibuya
            </h1>
          </FadeInV2>
          <FadeInV2 className="ease-in" direction="down" delay={500}>
            <div className="shib-body mb-6 flex max-w-[38rem] flex-col gap-4 drop-shadow-[0_4px_10px_rgba(29,29,31,0.25)] sm:mb-12">
              We provide creators with a full-stack solution to fund films and
              shorts, build community, and foster an engaged fanbase. Fans can
              fund their favorite films, participate in the creative process,
              and join communities for the films they love most.
            </div>
          </FadeInV2>

          <FadeInV2 className="ease-in" direction="down" delay={1000}>
            <ButtonV2
              category="primary-translucent"
              text="Explore Shibuya"
              className="w-full sm:w-auto"
              size="lg"
              onClick={() => scrollTo(1000)}
            />
          </FadeInV2>
        </div>
        <div className="relative hidden items-center justify-center sm:flex">
          <button
            className="absolute -top-20 z-10 animate-bounceFade"
            onClick={() => scrollTo(1000)}
          >
            <img src="/images/redesign/ChevronDown.svg" alt="Scroll down" />
          </button>
        </div>
        <div className="mt-16 lg:mt-16" ref={ref}>
          <FeaturedShows />
        </div>
        <div className="mt-10 lg:mt-16 lg:mb-5">
          <Tabs title="Learn more" tabs={COMPANY_INFO_TABS} />
        </div>

        <FooterV2 />
      </main>
    </div>
  );
};
export default LandingPage;
